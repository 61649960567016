export var AttachmentTypeLangId = {
    // Medical attachments
    DIAGNOSTIC_STUDY: 'ehs_incidents_label_attachmentTypeDiagnosticStudy',
    FCE_RESTRICTION: 'ehs_incidents_label_attachmentTypeFCERestrictions',
    PHYSICAL_THERAPY_NOTE: 'ehs_incidents_label_attachmentTypePhysicalTherapyNotes',
    PRESCRIPTION: 'ehs_incidents_label_attachmentTypePrescriptions',
    REFERRALS: 'ehs_incidents_label_attachmentTypeReferrals',
    RFI: 'ehs_incidents_label_attachmentTypeRFI',
    TRANSITIONAL_WORK_DOCUMENT: 'ehs_incidents_label_attachmentTypeTransitionalWorkDocument',
    WC_PACKET: 'ehs_incidents_label_attachmentTypeWCPacket',
    WORK_STATUS: 'ehs_incidents_label_attachmentTypeWorkStatus',
    MEDICAL_ATTACHMENTS_OTHER: 'ehs_incidents_label_attachmentTypeOther',
    // Regulatory
    REGULATORY_REPORT: 'ehs_incidents_label_attachmentTypeRegulatoryReportConfirmation',
    CONTESTED_CASE_REPORT: 'ehs_incidents_label_attachmentTypeContestedCaseCorrespondence',
    // Investigation
    PHOTOS_ACTUAL_INCIDENT: 'ehs_incidents_label_attachmentTypePhotosActualIncident',
    PHOTOS_RECREATED_INCIDENT: 'ehs_incidents_label_attachmentTypePhotosRecreatedIncident',
    VIDEO_FOOTAGE: 'ehs_incidents_label_attachmentTypeVideoFootage',
    AUDIO_RECORDING: 'ehs_incidents_label_attachmentTypeAudioRecording',
    EVIDENCE_NEAR_INCIDENT_LOCATION: 'ehs_incidents_label_attachmentTypeEvidenceNearIncidentLocation',
    DOC_ACTIVITY_OR_PROCESS: 'ehs_incidents_label_attachmentTypeDocumentationActivityProcess',
    DOC_ASSOCIATE_OR_PERSONAL: 'ehs_incidents_label_attachmentTypeDocumentationAssociatePersonal',
    DOC_EQUIPMENT: 'ehs_incidents_label_attachmentTypeDocumentationEquipment',
    DOC_STANDARDS_POLICIES_PROCEDURES: 'ehs_incidents_label_attachmentTypeDocumentationStandardsPoliciesProcedures',
    TECHNOLOGY_OR_COMPUTER_DATA: 'ehs_incidents_label_attachmentTypeTechnologyComputerData',
    INVESTIGATION_OTHER: 'ehs_incidents_label_attachmentTypeOther',
    WITNESS_STATEMENT: 'ehs_incidents_label_attachmentTypeWitnessStatement',
    EMERGENCY_RESPONSE_SERVICE: 'ehs_incidents_label_attachmentTypeEmergencyResponseService',
    ROUTING_DATA: 'ehs_incidents_label_attachmentTypeRoutingData',
    VEHICLE_TELEMATICS: 'ehs_incidents_label_attachmentTypeVehicleTelematics',
    VEHICLE_WORTHINESS_CHECK: 'ehs_incidents_label_attachmentTypeVehicleWorthinessCheck',
    POLICE_OR_CRASH_REPORT: 'ehs_incidents_label_attachmentTypePoliceOrCrashReport',
    IRF: 'ehs_incidents_label_attachmentTypeIRF',
    CONTRACTOR_MANAGEMENT: 'ehs_incidents_label_attachmentTypeContractorManagement'
};
export var AttachmentTypeDescriptionLangId = {
    // Medical attachments
    DIAGNOSTIC_STUDY: 'ehs_incidents_msg_attachmentTypeDiagnosticStudy',
    FCE_RESTRICTION: 'ehs_incidents_msg_attachmentTypeFCERestrictions',
    PHYSICAL_THERAPY_NOTE: 'ehs_incidents_msg_attachmentTypePhysicalTherapyNotes',
    PRESCRIPTION: 'ehs_incidents_msg_attachmentTypePrescriptions',
    REFERRALS: 'ehs_incidents_msg_attachmentTypeReferrals',
    RFI: 'ehs_incidents_msg_attachmentTypeRFI',
    TRANSITIONAL_WORK_DOCUMENT: 'ehs_incidents_msg_attachmentTypeTransitionalWorkDocument',
    WC_PACKET: 'ehs_incidents_msg_attachmentTypeWCPacket',
    WORK_STATUS: 'ehs_incidents_msg_attachmentTypeWorkStatus',
    MEDICAL_ATTACHMENTS_OTHER: 'ehs_incidents_msg_attachmentTypeMedicalOther',
    // Regulatory
    REGULATORY_REPORT: 'ehs_incidents_msg_attachmentTypeRegulatoryReportConfirmation',
    CONTESTED_CASE_REPORT: 'ehs_incidents_msg_attachmentTypeContestedCaseCorrespondence',
    // Investigation
    PHOTOS_ACTUAL_INCIDENT: 'ehs_incidents_msg_attachmentTypePhotosActualIncident',
    PHOTOS_RECREATED_INCIDENT: 'ehs_incidents_msg_attachmentTypePhotosRecreatedIncident',
    VIDEO_FOOTAGE: 'ehs_incidents_msg_attachmentTypeVideoFootage',
    AUDIO_RECORDING: 'ehs_incidents_msg_attachmentTypeAudioRecording',
    EVIDENCE_NEAR_INCIDENT_LOCATION: 'ehs_incidents_msg_attachmentTypeEvidenceNearIncidentLocation',
    DOC_ACTIVITY_OR_PROCESS: 'ehs_incidents_msg_attachmentTypeDocumentationActivityProcess',
    DOC_ASSOCIATE_OR_PERSONAL: 'ehs_incidents_msg_attachmentTypeDocumentationAssociatePersonal',
    DOC_EQUIPMENT: 'ehs_incidents_msg_attachmentTypeDocumentationEquipment',
    DOC_STANDARDS_POLICIES_PROCEDURES: 'ehs_incidents_msg_attachmentTypeDocumentationStandardsPoliciesProcedures',
    TECHNOLOGY_OR_COMPUTER_DATA: 'ehs_incidents_msg_attachmentTypeTechnologyComputerData',
    INVESTIGATION_OTHER: 'ehs_incidents_msg_attachmentTypeInvestigationOther',
    WITNESS_STATEMENT: 'ehs_incidents_msg_attachmentTypeWitnessStatement',
    EMERGENCY_RESPONSE_SERVICE: 'ehs_incidents_msg_attachmentTypeEmergencyResponseService',
    ROUTING_DATA: 'ehs_incidents_msg_attachmentTypeRoutingData',
    VEHICLE_TELEMATICS: 'ehs_incidents_msg_attachmentTypeVehicleTelematics',
    VEHICLE_WORTHINESS_CHECK: 'ehs_incidents_msg_attachmentTypeVehicleWorthinessCheck',
    POLICE_OR_CRASH_REPORT: 'ehs_incidents_msg_attachmentTypePoliceOrCrashReport',
    IRF: 'ehs_incidents_msg_attachmentTypeIRF',
    CONTRACTOR_MANAGEMENT: 'ehs_incidents_msg_attachmentTypeContractorManagement'
};
