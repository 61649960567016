/* istanbul ignore file */
import {
    awsCreateIncidentQuestions,
    createIncidentQuestions,
    createIncidentWithPapiQuestions
} from '../../questions/createIncident';
import { createNonAmazonian } from '../../questions/createNonAmazonian';

export const FORM_TYPES = {
    CREATE_INCIDENT: 'CREATE_INCIDENT',
    CREATE_INCIDENT_AWS: 'CREATE_INCIDENT_AWS',
    CREATE_INCIDENT_PAPI: 'CREATE_INCIDENT_PAPI',
    CREATE_CONTRACTOR: 'CREATE_CONTRACTOR'
};

export const formTemplates = [
    {
        id: 'frm-tpl-crt-whs',
        name: 'What do you want to record?',
        type: FORM_TYPES.CREATE_INCIDENT,
        questions: createIncidentQuestions
    },
    {
        id: 'frm-tpl-crt-aws',
        name: 'What do you want to record?',
        type: FORM_TYPES.CREATE_INCIDENT_AWS,
        questions: awsCreateIncidentQuestions
    },
    {
        id: 'frm-tpl-crt-whs-papi',
        name: 'What do you want to record?',
        type: FORM_TYPES.CREATE_INCIDENT_PAPI,
        questions: createIncidentWithPapiQuestions
    },
    {
        id: 'frm-tpl-cntr',
        name: 'Contractor Info',
        type: FORM_TYPES.CREATE_CONTRACTOR,
        sections: createNonAmazonian
    }
];
