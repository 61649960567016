var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
import { useMemo } from 'react';
import { isAws, usePermissions, RESOURCE_NAMESPACE } from '@amzn/austin-core';
import { IncidentsResourceNames } from '../../../common/types';
// @todo consolidate these field names with Table column definitions
export var FIELDS = {
    AMCARE_BYPASS: 'amCareBypass',
    ASSIGNEE_INITIAL_ENCOUNTER: 'initialEncounterAssignee',
    ASSIGNEE_INITIAL_INFORMATION: 'initialInformationAssignee',
    ASSIGNEE_INVESTIGATION: 'investigationAssignee',
    ASSIGNEE_RCA: 'rcaAssignee',
    ASSIGNEE_REGULATORY: 'regulatoryAssignee',
    CASE_NUMBER: 'caseNumber',
    CREATED_AT: 'createdAt',
    CREATOR: 'creator',
    DAYS_OPEN: 'daysOpen',
    DAYS_SINCE_UPDATED: 'daysSinceUpdated',
    DEPARTMENT: 'department',
    DESCRIPTION: 'description',
    EMPLOYEE_TYPE: 'employeeType',
    HAS_DAFW: 'hasDAFW',
    HAS_RWA: 'hasRWA',
    INCIDENT_DATE_TIME: 'incidentDateTime',
    INCIDENT_GROUP_ID: 'incidentGroupId',
    INCIDENT_PROCESS_PATH: 'incidentProcessPath',
    INCIDENT_REPORTED_DATE: 'incidentReportedDate',
    INCIDENT_STATUS: 'status',
    INCIDENT_TYPE: 'incidentType',
    INITIAL_RISK: 'initialRisk',
    INJURED_ALIAS: 'injuredAlias',
    LATEST_FOLLOWUP_DATE: 'latestFollowupDate',
    LATEST_ICARE_DATE: 'latestIcareDate',
    LOCATION: 'location',
    MANAGER_ALIAS: 'managerAlias',
    MEDICAL_STATUS: 'medicalStatus',
    PENDING_DAFW_END_DATE: 'pendingDAFWEndDate',
    PENDING_RWA_END_DATE: 'pendingRWAEndDate',
    POTENTIAL_SEVERITY: 'potentialSeverity',
    PRINCIPAL_BODY_PART: 'principalBodyPart',
    RECORDABLE: 'recordable',
    REPORTABLE: 'reportable',
    RISK: 'risk',
    SEVERITY: 'severity',
    SHIFT: 'shift',
    SITE_NAME: 'site',
    STATUS_ACTIONS: 'actionsPhaseStatus',
    STATUS_FOLLOWUP: 'followupPhaseStatus',
    STATUS_ICARE: 'icarePhaseStatus',
    STATUS_INITIAL_ENCOUNTER: 'initialEncounterPhaseStatus',
    STATUS_INITIAL_INFORMATION: 'initialInformationPhaseStatus',
    STATUS_INVESTIGATION: 'investigationPhaseStatus',
    STATUS_RCA: 'rcaPhaseStatus',
    STATUS_REGULATORY: 'regulatoryPhaseStatus',
    WORK_RELATED: 'workRelated'
};
var RESTRICTED_FIELD_SET = new Set([
    FIELDS.CASE_NUMBER,
    FIELDS.INCIDENT_GROUP_ID,
    FIELDS.SITE_NAME,
    FIELDS.INCIDENT_STATUS,
    FIELDS.CREATED_AT,
    FIELDS.INCIDENT_TYPE
]);
var DEFAULT_FIELD_SET = new Set(Object.values(FIELDS));
export var DASHBOARD_ACTIONS = {
    CREATE_INCIDENTS: 'createIncidents',
    VIEW_FILTER_VIEWS: 'viewFilterViews',
    VIEW_PREFERENCES: 'viewPreferences',
    VIEW_METRICS: 'viewMetrics'
};
var CREATE_INCIDENTS = DASHBOARD_ACTIONS.CREATE_INCIDENTS, VIEW_FILTER_VIEWS = DASHBOARD_ACTIONS.VIEW_FILTER_VIEWS, VIEW_PREFERENCES = DASHBOARD_ACTIONS.VIEW_PREFERENCES, VIEW_METRICS = DASHBOARD_ACTIONS.VIEW_METRICS;
export var useDashboard = function () {
    var canViewAllFields = usePermissions(RESOURCE_NAMESPACE.INCIDENTS, IncidentsResourceNames.SEARCH_LIST, false).canView;
    var canCreateIncidents = usePermissions(RESOURCE_NAMESPACE.INCIDENTS, IncidentsResourceNames.INCIDENT, false).canEdit;
    // AWS requires additional permission to view all incidents at site
    var restrictFields = isAws() && !canViewAllFields;
    var dataFields = useMemo(initializeFields, [
        restrictFields
    ]);
    var availableActions = useMemo(function () {
        var _a;
        return (_a = {},
            _a[CREATE_INCIDENTS] = {
                enabled: canCreateIncidents
            },
            _a[VIEW_FILTER_VIEWS] = {
                enabled: !restrictFields
            },
            _a[VIEW_PREFERENCES] = {
                enabled: true
            },
            _a[VIEW_METRICS] = {
                enabled: !restrictFields
            },
            _a);
    }, [canCreateIncidents, restrictFields]);
    var availableSearchFilters = useMemo(getAvailableSearchFields, [dataFields]);
    function initializeFields() {
        var allowedFields = restrictFields
            ? RESTRICTED_FIELD_SET
            : DEFAULT_FIELD_SET;
        return Object.values(FIELDS).reduce(function (fields, fieldName) {
            var _a;
            return Object.assign(fields, (_a = {},
                _a[fieldName] = {
                    enabled: Boolean(allowedFields.has(fieldName))
                },
                _a));
        }, {});
    }
    function getAvailableSearchFields() {
        return __assign(__assign({}, dataFields), { caseConditions: {
                enabled: !restrictFields
            }, externalService: {
                enabled: !restrictFields
            }, hierarchy: {
                enabled: true
            }, search: { enabled: !restrictFields }, searchBy: { enabled: !restrictFields }, type: { enabled: !restrictFields } });
    }
    function canSearchByField(fieldName) {
        return Boolean(get(availableSearchFilters, "".concat(fieldName, ".enabled"), false));
    }
    function canPerformAction(actionName) {
        return Boolean(get(availableActions, "".concat(actionName, ".enabled"), false));
    }
    function canViewField(fieldName) {
        return Boolean(get(dataFields, "".concat(fieldName, ".enabled"), false));
    }
    return {
        dataFields: dataFields,
        canPerformAction: canPerformAction,
        canSearchByField: canSearchByField,
        canViewField: canViewField,
        canViewAllFields: !restrictFields,
        getAvailableSearchFields: getAvailableSearchFields
    };
};
export default useDashboard;
