import { addDays, endOfDay, formatISO, parseISO, startOfDay, isValid, format, subDays, parse } from 'date-fns';
import chunk from 'lodash/chunk';
import { formatInTimeZone } from 'date-fns-tz';
export var DATE_DISPLAY_FORMAT = 'MM/dd/yyyy';
export var DATE_CONSTRAINTS = {
    FUTURE_DATE: 'FUTURE_DATE',
    NO_OVERLAP: 'NO_OVERLAP',
    PAST_DATE: 'PAST_DATE'
};
var FUTURE_DATE = DATE_CONSTRAINTS.FUTURE_DATE, PAST_DATE = DATE_CONSTRAINTS.PAST_DATE;
/**
 * Given 2 separate dates|timestamps (date, time) will parse and combine together
 *
 * @param {Date | string} [date]
 * Date object or ISO timestamp representing the date yyyy-mm-dd
 * @param {Date | string} [time]
 * Date object or ISO timestamp representing the time hh:mm:ss (yyyy-mm-dd date portion will be replaced)
 *
 * @returns {String}
 * ISO timestamp of combined date + time of format yyyy-mm-ddThh:mm:ssZ i.e. 2023-10-10T10:00:00Z
 */
export function getCombinedDateAndTime(date, time) {
    var dateTime = null;
    try {
        var currentDate = date ? new Date(date) : new Date();
        var currentTime = time ? new Date(time) : new Date();
        var newDate = formatISO(new Date(currentDate.setMinutes(currentDate.getTimezoneOffset())), { representation: 'date' });
        var newTime = formatISO(currentTime.setMinutes(currentTime.getMinutes() +
            currentDate.getTimezoneOffset() -
            currentTime.getTimezoneOffset()), { representation: 'time' });
        dateTime = parseISO("".concat(newDate, "T").concat(newTime)).toISOString();
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error combining date and time values: ".concat(date, ", ").concat(time), err);
    }
    return dateTime;
}
/**
 *
 * @param {Date | String} date
 * Date object or date string without timezone (yyyy-mm-dd)
 *
 * @returns {Date}
 * Date object with local timezone offset
 */
export function getDateWithTimezoneOffset(date) {
    var unconvertedDate = new Date(date);
    // use the time zone offset converted to milliseconds to handle UTC conversion
    return new Date(unconvertedDate.getTime() + unconvertedDate.getTimezoneOffset() * 60000);
}
/**
 * Helper for min/max date constraints passed to Date fields
 * Parses a date string or passed date object and a defined DATE_CONSTRAINT type (PAST_DATE, FUTURE_DATE)
 * Returns an object with minDate, maxDate ISO string values
 *
 * @param {DateFieldConstraint} constraint
 * PAST_DATE, FUTURE_DATE as defined a question answerTemplate
 *
 * @param {Date|String} date
 * Comparison date to adjust
 *
 *
 * @returns {Object}
 * { minDate: "2023-11-28T22:03:55.078Z", maxDate: "2023-11-28T22:03:55.078Z" }
 */
export function getMinMaxDates(constraint, date) {
    var minDate, maxDate;
    var comparisonDate = date ? new Date(date) : new Date();
    try {
        if (constraint === PAST_DATE) {
            maxDate = comparisonDate.toISOString();
        }
        else if (constraint === FUTURE_DATE) {
            minDate = comparisonDate.toISOString();
        }
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error parsing min/max date', err);
    }
    return {
        minDate: minDate,
        maxDate: maxDate
    };
}
/**
 * Helper for min/max date constraints passed to Date fields
 * Parses a date string or passed date object
 * Returns a date with passed offset by days.
 * If no comparison date is passed, will return undefined which is passed to the component
 *
 * @param {Date|String} date
 * Comparison date to adjust
 *
 * @param {Number} dayOffset
 * Number of days to adjust the date constraint.  Negative numbers will substract days.
 *
 * @returns {Date | undefined}
 */
export function getMinMaxDateWithOffset(date, dayOffset) {
    if (dayOffset === void 0) { dayOffset = 0; }
    var minMaxDate;
    if (date) {
        try {
            var parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date);
            if (isValid(parsedDate)) {
                minMaxDate = addDays(parsedDate, dayOffset);
            }
        }
        catch (_a) {
            // ignore
        }
    }
    return minMaxDate;
}
/**
 * Helper to get timestamp for end of the day
 * @param {Date | String} date
 * @returns {Date}
 */
export function getDateStringEndOfDay(date) {
    return endOfDay(new Date(getDateWithTimezoneOffset(date))).toISOString();
}
/**
 * Helper to get timestamp for start of the day
 * @param {Date | String} date
 * @returns {Date}
 */
export function getDateStringStartOfDay(date) {
    return startOfDay(new Date(getDateWithTimezoneOffset(date))).toISOString();
}
/**
 * Helper to map date tuples to object
 * arr1 [2022-01-01, 2022-01-10, 2022-01-16]
 * arr2 [2022-01-20, 2022-01-22]
 *
 * @private
 */
function mapStartEnd(val) {
    return {
        start: val[0] ? new Date(val[0]).getTime() : undefined,
        end: val[1] ? new Date(val[1]).getTime() : undefined
    };
}
/**
 * Compares two arrays of date strings (assuming tuple of start, end timestamps) and determines if there is overlap between the ranges.
 * If both ranges have a missing end date, they are both "open ended" and imply overlap.
 *
 * @param {Array} val1
 * Array of timestamps
 *
 * @param {Array} val2
 * Second Array of timestamps t compare against
 *
 * @returns {Boolean}
 */
export function hasOverlappingDateRanges(val1, val2) {
    var dateRanges1 = chunk(val1, 2).map(mapStartEnd);
    var dateRanges2 = chunk(val2, 2).map(mapStartEnd);
    return dateRanges1.some(function (_a, index1) {
        var _b = _a.start, start1 = _b === void 0 ? 0 : _b, _c = _a.end, end1 = _c === void 0 ? 0 : _c;
        return dateRanges2.some(function (dates, index2) {
            var _a = dates.start, start2 = _a === void 0 ? 0 : _a, _b = dates.end, end2 = _b === void 0 ? 0 : _b;
            // Multiple date ranges can't have open end dates
            // If both start and end date are undefined, treat as unanswered
            var range1MissingEndDate = index1 === dateRanges1.length - 1 && Boolean(start1) && !end1;
            var range2MissingEndDate = index2 === dateRanges2.length - 1 && Boolean(start2) && !end2;
            // If date range is open and could fall between comparison rang
            var range1EndCanInterlap = range1MissingEndDate && (start1 <= end2 || start1 <= start2);
            var range2EndCanInterlap = range2MissingEndDate && (start2 <= end1 || start2 <= start1);
            return ((range1MissingEndDate && range2MissingEndDate) ||
                range1EndCanInterlap ||
                range2EndCanInterlap ||
                (start2 >= start1 && start2 <= end1) ||
                (end2 >= start1 && end2 <= end1) ||
                (start1 >= start2 && start1 <= end2) ||
                (end2 >= start1 && start2 <= end1));
        });
    });
}
/**
 * Helper for DateRange component validation.
 * - Date ranges can have an empty Start/End.  This will be omitted on form save
 * - Date range can have the last entry have a start, with no end.  This implies an open ended date.
 * - Ranges can have multiple tuples of [start, end] time stamps
 * - Start and end can be the same date, this implies a single day
 * - Start dates of the subsequent entry cannot be less than the previous end date
 *
 * @param {Array} values
 * Array of date timestamp tuples [start1, end1, start2, end2...]
 *
 * @returns {Boolean}
 */
export function isValidDateRange(values) {
    if (values === void 0) { values = []; }
    function createRanges(items) {
        if (items === void 0) { items = []; }
        return chunk(items, 2).map(function (v) {
            var _a, _b;
            return ({
                startDate: (_a = v[0]) !== null && _a !== void 0 ? _a : '',
                endDate: (_b = v[1]) !== null && _b !== void 0 ? _b : ''
            });
        });
    }
    var ranges = createRanges(values);
    var valid = ranges.every(function (_a, index) {
        var _b;
        var startDate = _a.startDate, endDate = _a.endDate;
        var isFirstItem = index === 0;
        var isLastItem = index === ranges.length - 1;
        var hasStartDate = Boolean(startDate);
        var hasEndDate = Boolean(endDate);
        // Each range, besides the last, needs a startDate and endDate
        var hasRequiredValues = hasStartDate && hasEndDate;
        // The endDate of the current range should be greater than or equal to the startDate
        var endGtStart = new Date(endDate) >= new Date(startDate);
        // Each range start should be greater than the previous range end
        var startGtPreviousEnd = isFirstItem ||
            new Date(startDate) > new Date((_b = ranges[index - 1]) === null || _b === void 0 ? void 0 : _b.endDate);
        // Allow last range to be empty, will be trimmed from answer
        // End date can be empty, if start date isn't, or both can be empty
        var isLastItemAndUnanswered = isLastItem && !hasStartDate && !hasEndDate;
        var isLastItemandEndDateEmpty = isLastItem && hasStartDate && !hasEndDate && startGtPreviousEnd;
        var isValidRange = isLastItemAndUnanswered ||
            isLastItemandEndDateEmpty ||
            (hasRequiredValues && endGtStart && startGtPreviousEnd);
        return isValidRange;
    });
    return valid;
}
/**
 * Helper to validate if a time zone is a valid time zone
 * @param {String} timeZone
 * @returns {boolean}
 */
export function isValidTimeZone(timeZone) {
    try {
        Intl.DateTimeFormat(undefined, { timeZone: timeZone });
        return true;
    }
    catch (ex) {
        return false;
    }
}
/**
 * Helper to get valid time zone from incident data - customAttributes 'Time Zone' (site time zone). If site time zone'
 * is present and valid, return it. Otherwise returns default browser time zone:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param {Object} incidentDataCustomAttributeMap
 * @param {String} defaultTimeZone
 * @returns {String}
 */
// TODO: call getLocalTimeZone from Austin-core to get the default time zone instead of reading from browser & update test
export function getSiteOrDefaultTimeZone(_a) {
    var incidentDataCustomAttributeMap = _a.incidentDataCustomAttributeMap, _b = _a.defaultTimeZone, defaultTimeZone = _b === void 0 ? Intl.DateTimeFormat().resolvedOptions().timeZone : _b;
    var siteTimeZone = incidentDataCustomAttributeMap === null || incidentDataCustomAttributeMap === void 0 ? void 0 : incidentDataCustomAttributeMap['Time Zone'];
    var hasValidSiteTimezone = !!siteTimeZone && isValidTimeZone(siteTimeZone);
    return hasValidSiteTimezone ? siteTimeZone : defaultTimeZone;
}
export function getAbsoluteDateValueFromRelativeDays(_a) {
    var endDate = _a.endDate, delta = _a.delta, _b = _a.endDateFormat, endDateFormat = _b === void 0 ? 'yyyy-MM-dd' : _b, _c = _a.dateFormat, dateFormat = _c === void 0 ? 'yyyy-MM-dd' : _c;
    try {
        var end = endDate && endDateFormat
            ? parse(endDate, endDateFormat, new Date())
            : new Date();
        var lastDate = subDays(end, delta);
        return format(lastDate, dateFormat);
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Unable to parse input date, returning current date in the specified date format');
    }
    return format(new Date(), dateFormat);
}
/**
 * Formats a date string for display as per their locale. Return original date string if parsing fail
 * @param {String|Date} date
 * Date string yyyy-mm-dd
 */
export function getLocalizedDateDisplay(date) {
    try {
        var parsedDate = void 0;
        if (isValid(date)) {
            parsedDate = date.toLocaleString();
        }
        else if (typeof date === 'string') {
            parsedDate = parseISO(date).toLocaleDateString();
        }
        return parsedDate || date;
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error formatting followup date', e);
        return date;
    }
}
export function hasValidSiteTimeZone(timeZone) {
    return !!timeZone && isValidTimeZone(timeZone);
}
export function getTimeZoneDisplayText(timeZone) {
    // default browser timezone
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    var userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var siteOrUserTimeZone = hasValidSiteTimeZone(timeZone)
        ? timeZone
        : userTimezone;
    return siteOrUserTimeZone
        ? formatInTimeZone(new Date(), siteOrUserTimeZone, 'z')
        : '';
}
